import React, { useRef, useEffect, useContext } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { Container } from "components/container/Container";
import { RichText } from "components/contentful/rich-text/RichText";
import { Button } from "components/button/Button";
import { Image } from "components/contentful/image/Image";
import { DashedAnimations } from "components/dashed-animation/DashedAnimations";
import { IDashedAnimationsItemProps } from "components/dashed-animation/DashedAnimationsItem";

import { PrismicImage } from "types/prismicImage";
import { PrismicVideo } from "types/PrismicVideo";
import { PrismicRichText } from "types/prismicRichText";

import { UIContext } from "context/ui";

import { scroll } from "constants/Scroll";

import s from "./MediaText.scss";
import { VideoPlayer } from "../video-player/VideoPlayer";
import { VideoItem } from "components/video-item/VideoItem";

interface IProps {
  image: PrismicImage;
  imageMobile?: PrismicImage;
  video: PrismicVideo;
  title?: string;
  text?: PrismicRichText;
  cta?: {
    label: string;
    href: string;
  };
  inverted?: boolean;
  invertedOnlyMobile?: boolean;
  layout?: "grid" | "default" | "column";
  narrow?: boolean;
  noSpacingTop?: boolean;
  noSpacingBottom?: boolean;
  extraSpacingBottom?: boolean;
  target?: string;
  mediaTextRef?: any;
  extraIndent?: boolean;
  titleAsH3?: boolean;
  textAlignTop?: boolean;
  dashedAnimations?: Array<IDashedAnimationsItemProps>;
  disableScroll?: boolean;
  backgroundImage?: PrismicImage;
  topRadius?: boolean;
  borderRadiusTop?: boolean;
  bottomLeftRadius?: boolean;
  noAspectRatio?: boolean;
  bottomCta?: boolean;
}

export const MediaTextContentful = ({
  mediaText,
  invertedOnlyMobile,
  layout,
  inverted,
  narrow,
  noSpacingTop,
  noSpacingBottom,
  extraSpacingBottom,
  target,
  mediaTextRef,
  extraIndent = false,
  titleAsH3 = false,
  textAlignTop = false,
  dashedAnimations,
  disableScroll = false,
  topRadius = false,
  borderRadiusTop = false,
  bottomLeftRadius = false,
  topRightRadius = false,
  informationOnTop = false,
  noAspectRatio = false,
  smallerTitle = false,
  style,
  paddingTop,
  textStyle,
  titleStyle,
  pStyle,
  noMarginTop = false,
  notFullWidth = false,
  hasParallax = true,
  unsetMinHeight = false,
  bottomCta = false,
}: any) => {
  const layoutGrid = layout == "grid";
  const layoutCol = layout == "column";
  const imgBorderRef = useRef<HTMLDivElement>(null);
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoSourceRef = useRef<HTMLSourceElement>(null);
  const autoPlay = mediaText.autoplay;
  const videoUrl = mediaText?.videoUrl;
  const image = mediaText?.image;
  const imageMobile = mediaText?.imageMobile;
  const video = mediaText?.video;
  const title = mediaText?.title;
  const text = mediaText.text;
  const infoTop = informationOnTop;
  const parallax = hasParallax;
  const backgroundImage = mediaText?.sectionBackground;
  const background = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage.file.url})`,
        backgroundSize: "cover"
      }
    : {};
  const cta = mediaText.cta;
  const swapped = mediaText?.inverted;
  const { isDesktop, isMobile } = useContext(UIContext);

  useEffect(() => {
    if (isDesktop && imageRef.current && !video && !disableScroll) {
      setTimeout(() => {
        if (imageRef.current) {
          const figure = imageRef.current.querySelector("figure");
          if (figure && parallax) gsap.set(figure, { scale: 1.3 });

          const video = imageRef.current.querySelector("video");
          if (video && parallax) gsap.set(video, { scale: 1.3 });

          if (parallax) {
            gsap.fromTo(
              imageRef.current,
              { y: -100 },
              {
                y: 100,
                scrollTrigger: {
                  scroller: scroll.container,
                  trigger: imageRef.current,
                  scrub: true
                }
              }
            );
          }
        }
      }, 1500);
    }
  }, [isDesktop]);

  return (
    <div
      className={s("mediaText", {
        inverted: inverted || swapped,
        invertedOnlyMobile,
        layoutGrid,
        layoutCol,
        narrow,
        noSpacingTop,
        noSpacingBottom,
        extraSpacingBottom,
        extraIndent,
        titleAsH3,
        textAlignTop,
        topRadius,
        smallerTitle,
        topRightRadius,
        borderRadiusTop,
        bottomLeftRadius,
        noAspectRatio,
        notFullWidth,
        noMarginTop,
        unsetMinHeight
      })}
      data-target={target}
      ref={mediaTextRef}
      style={{ ...background, paddingTop: paddingTop, ...style }}
    >
      <Container>
        <div className={`${s.mediaText__row} | js-t-block`}>
          {infoTop && (title || text || cta) && (
            <div className={s.mediaText__content} style={textStyle}>
              <div>
                {title && (
                  <h2
                    style={titleStyle}
                    className={`${s.mediaText__title} | js-t-title`}
                  >
                    {title}
                  </h2>
                )}
                {!title && (
                  <h2 className={`u-visually-hidden`} aria-hidden="true">
                    Media
                  </h2>
                )}
                {text && (
                  <div
                    style={pStyle}
                    className={`${s.mediaText__text} | js-t-text`}
                  >
                    <RichText richText={text} />
                  </div>
                )}
                {cta?.label && !bottomCta && (
                  <div className={`${s.mediaText__ctaContainer} | js-t-btn`}>
                    <Button
                      label={cta.label}
                      href={cta.file ? cta.file.file.url : cta.href}
                    ></Button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            ref={imgBorderRef}
            className={s.mediaText__img}
            style={
              (video || videoUrl) && !noAspectRatio && imageRef.current
                ? {
                    minHeight:
                      ((imageRef.current?.offsetWidth * 1130) / 910) * 1.011
                  }
                : {}
            }
          >
            <div ref={imgWrapRef}>
              <div ref={imageRef}>
                {dashedAnimations && (
                  <DashedAnimations items={dashedAnimations} />
                )}
                {(video || videoUrl) && (
                  <VideoItem
                    item={{
                      video: mediaText.video,
                      videoUrl: mediaText.videoUrl,
                      showPlayButton: false,
                      description: mediaText.video?.description,
                      autoplay: true
                    }}
                    autoplay={true}
                    loop={true}
                    borderRadiusTop={true}
                  />
                )}
                {!video && (isDesktop || !imageMobile) && image && (
                  <Image image={image} srcSetMobile={isMobile} />
                )}

                {!video && imageMobile && isMobile && (
                  <Image image={imageMobile} />
                )}
              </div>
            </div>
          </div>
          {!infoTop && (title || text || cta) && (
            <div className={s.mediaText__content} style={textStyle}>
              <div>
                {title && (
                  <h2
                    style={titleStyle}
                    className={`${s.mediaText__title} | js-t-title`}
                  >
                    {title}
                  </h2>
                )}
                {!title && (
                  <h2 className={`u-visually-hidden`} aria-hidden="true">
                    Media
                  </h2>
                )}
                {text && (
                  <div
                    style={pStyle}
                    className={`${s.mediaText__text} | js-t-text`}
                  >
                    <RichText richText={text} />
                  </div>
                )}
                {cta?.label && (
                  <div className={`${s.mediaText__ctaContainer} | js-t-btn`}>
                    <Button
                      label={cta.label}
                      href={cta.file ? cta.file.file.url : cta.href}
                    ></Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {bottomCta && cta?.label && (
          <div className={s.mediaText__content} style={textStyle}>
            <div>
              {cta?.label && (
                <div className={`${s.mediaText__ctaContainer} | js-t-btn`}>
                  <Button
                    label={cta.label}
                    href={cta.file ? cta.file.file.url : cta.href}
                  ></Button>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};
